@media screen and (min-width: 1024px) {
  .weare {
    justify-content: center;
    display: flex;
    flex-direction: column;    
    .textWeAre {
      color: var(--primaryColor) !important;
      text-align: center;
      font-size: 24px;
      line-height: 1.7rem;
      .boldText {
        font-family: "Montserrat Bold";
      }
    }
    .numbersDontLie {
      margin-top: 90pt;
      color: var(--primaryColor) !important;
      text-align: center;
      font-size: 24px;
      .boldText {
        font-family: "Montserrat Bold";
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .weare {
    display: flex;
    // flex-direction: column;
    width: 100%;
    .textWeAre {
      color: var(--primaryColor) !important;
      text-align: center;
      font-size: 24px;
      .boldText {
        font-family: "Montserrat Bold";
      }
    }
    .textWeAreMobile {
      justify-content: space-between;
      color: var(--primaryColor) !important;
      text-align: center;
      font-size: 1.5rem;
      .boldText {
        font-family: "Montserrat Bold";
      }
    }
    .numbersDontLie {
      margin-top: 10%;
      color: var(--primaryColor) !important;
      text-align: center;
      font-size: 2rem;
      .boldText {
        font-family: "Montserrat Bold";
      }
    }
  }
}

@media screen and (max-width: 767px) {
	.weare {
    display: flex;
    // flex-direction: column;
    width: 100%;
    .textWeAreMobile {
      justify-content: space-between;
      // width: 90%;
      color: var(--primaryColor) !important;
      text-align: center;
      font-size: 1.5rem;
      .boldText {
        font-family: "Montserrat Bold";
      }
    }
    .numbersDontLie {
      margin-top: 10%;
      color: var(--primaryColor) !important;
      text-align: center;
      font-size: 2rem;
      .boldText {
        font-family: "Montserrat Bold";
      }
    }
  }
}