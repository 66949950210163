@media screen and (min-width: 1024px) {
  .brand {
    margin-top: 10%;
    margin-bottom: 8%;
    text-align: center;
    .colorLogoImg {
      width: 17%;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .brand {
    margin-top: 20%;
    margin-bottom: 8%;
    text-align: center;
    .colorLogoImg {
      width: 40%;
    }
  }
}

@media screen and (max-width: 767px) {
  .brand {
    margin-top: 35%;
    margin-bottom: 8%;
    text-align: center;
    .colorLogoImg {
      width: 40%;
    }
  }
}
