@media screen and (min-width: 1024px) {
  .logos {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .Logo {
      width: 100%;
      max-width: 80%;
    }
    .textos {
      width: 90%;
      text-align: center;
      padding-top: 20px;
      color: var(--primaryColor) !important;
      line-height: 1.3rem;
      .boldText {
        font-family: "Montserrat Bold";
      }
    }
  }
}

@media screen and (max-width: 1023px) {
   .logos {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .Logo {
      width: 100%;
      max-width: 50%;
    }
    .textos {
      width: 55%;
      text-align: center;
      padding-top: 20px;
      color: var(--primaryColor) !important;
      line-height: 1.3rem;
      .boldText {
        font-family: "Montserrat Bold";
      }
      padding-bottom: 40px;
    }
  }
}
