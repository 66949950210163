@media screen and (min-width: 1024px) {
  .green {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: safe center;
    background-color: #c4f659;
    width: 100%;
    height: 107px;
    margin: auto;
    margin-top: 5%;
    margin-bottom: 20px;
    color: #171734 !important;
    border-top-right-radius: 60px;
    border-bottom-left-radius: 60px;
    .boldText {
      font-size: 4rem;
      font-family: "Montserrat ExtraBold";
    }
    .lightText {
      font-family: "Montserrat SemiBold";
      font-size: 2rem;
      span {
        margin-left: 20px;
      }
    }
  }
  .rose1 {
    display: flex;
    flex-direction: column;
    background-color: #e03278;
    width: 100%;
    height: 180px;
    color: var(--primaryColor) !important;
    border-top-right-radius: 60px;
    border-bottom-left-radius: 60px;
    .text {
      margin: auto;
    }
    .boldText {
      font-size: 1rem;
      font-family: "Montserrat SemiBold";
    }
    .bolderText {
      font-size: 4rem;
      line-height: 3.5rem;
      font-family: "Montserrat ExtraBold";
    }
    .lightText {
      font-size: 0.7rem;
      font-family: "Montserrat SemiBold";
      .spanjr {
        line-height: 0.1rem;
      }
    }
  }
  .group2 {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    width: 100%;
    .lightBlue {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: safe center;
      background-color: #a4efeb;
      border-top-right-radius: 40px;
      border-bottom-left-radius: 40px;
      height: 70px;
      width: 100%;
      color: #171734;
      .boldText {
        // text-align:inherit;
        font-size: 2rem;
        font-family: "Montserrat ExtraBold";
      }
      .textos {
        margin-left: 20px;
        .lightText {
          font-size: 1.2rem;
          font-family: "Montserrat SemiBold";
        }
      }
    }
    .group3 {
      display: flex;
      flex-direction: row;
      margin-top: 20px;
      width: 100%;
      .Blue {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: safe center;
        background-color: #171734;
        border-top-right-radius: 40px;
        border-bottom-left-radius: 40px;
        color: var(--primaryColor);
        width: 100%;
        height: 90px;
        line-height: 2rem;
        .boldText {
          font-size: 2.6rem;
          font-family: "Montserrat ExtraBold";
        }
        .lightText {
          font-size: 1rem;
          font-family: "Montserrat SemiBold";
        }
      }
      .rose2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: safe center;
        background-color: #e03278;
        border-top-right-radius: 40px;
        border-bottom-left-radius: 40px;
        color: var(--primaryColor);
        width: 100%;
        .boldText {
          font-size: 2.6rem;
          font-family: "Montserrat ExtraBold";
          line-height: 2rem;
        }
        .lightText {
          font-size: 1rem;
          font-family: "Montserrat SemiBold";
        }
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .green {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: safe center;
    background-color: #c4f659;
    width: 100%;
    height: 107px;
    margin: auto;
    margin-top: 5%;
    margin-bottom: 20px;
    color: #171734 !important;
    border-top-right-radius: 60px;
    border-bottom-left-radius: 60px;
    .boldText {
      font-size: 4rem;
      font-family: "Montserrat ExtraBold";
    }
    .lightText {
      font-family: "Montserrat SemiBold";
      font-size: 2rem;
      span {
        margin-left: 20px;
      }
    }
  }
  .rose1 {
    display: flex;
    flex-direction: column;
    background-color: #e03278;
    width: 100%;
    height: 180px;
    color: var(--primaryColor) !important;
    border-top-right-radius: 60px;
    border-bottom-left-radius: 60px;
    .text {
      margin: auto;
    }
    .boldText {
      // text-align:inherit;
      font-size: 1rem;
      font-family: "Montserrat SemiBold";
    }
    .bolderText {
      font-size: 4rem;
      line-height: 3.5rem;
      font-family: "Montserrat ExtraBold";
    }
    .lightText {
      font-size: 0.7rem;
      font-family: "Montserrat SemiBold";
    }
  }
  .group2 {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    width: 100%;
    .lightBlue {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: safe center;
      background-color: #a4efeb;
      border-top-right-radius: 40px;
      border-bottom-left-radius: 40px;
      height: 70px;
      width: 100%;
      color: #171734;
      .boldText {
        // text-align:inherit;
        font-size: 2rem;
        font-family: "Montserrat ExtraBold";
      }
      .textos {
        margin-left: 20px;
        .lightText {
          font-size: 1.2rem;
          font-family: "Montserrat SemiBold";
        }
      }
    }
    .group3 {
      display: flex;
      flex-direction: row;
      margin-top: 20px;
      width: 100%;
      .Blue {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: safe center;
        background-color: #171734;
        border-top-right-radius: 40px;
        border-bottom-left-radius: 40px;
        color: var(--primaryColor);
        width: 100%;
        height: 90px;
        line-height: 2rem;
        .boldText {
          font-size: 2.6rem;
          font-family: "Montserrat ExtraBold";
        }
        .lightText {
          font-size: 1rem;
          font-family: "Montserrat SemiBold";
        }
      }
      .rose2 {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: safe center;
        background-color: #e03278;
        border-top-right-radius: 40px;
        border-bottom-left-radius: 40px;
        color: var(--primaryColor);
        width: 100%;
        height: 90px;
        .boldText {
          font-size: 2.4rem;
          font-family: "Montserrat ExtraBold";
          line-height: 2rem;
        }
        .lightText {
          font-size: 1rem;
          font-family: "Montserrat SemiBold";
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .green {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: safe center;
    background-color: #c4f659;
    width: 100%;
    height: 107px;
    margin: auto;
    margin-top: 5%;
    margin-bottom: 20px;
    color: #171734 !important;
    border-top-right-radius: 60px;
    border-bottom-left-radius: 60px;
    .boldText {
      font-size: 4rem;
      font-family: "Montserrat ExtraBold";
    }
    .lightText {
      font-family: "Montserrat SemiBold";
      font-size: 2rem;
      span {
        margin-left: 20px;
      }
    }
  }
  .group2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    .lightBlue {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: safe center;
      background-color: #a4efeb;
      border-top-right-radius: 40px;
      border-bottom-left-radius: 40px;
      height: 70px;
      width: 100%;
      color: #171734;
      .boldText {
        // text-align:inherit;
        font-size: 2rem;
        font-family: "Montserrat ExtraBold";
      }
      .textos {
        margin-left: 20px;
        .lightText {
          font-size: 1.2rem;
          font-family: "Montserrat SemiBold";
        }
      }
    }
    .group3 {
      display: flex;
      flex-direction: row;
      margin-top: 20px;
      width: 100%;
      .Blue {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: safe center;
        background-color: #171734;
        border-top-right-radius: 40px;
        border-bottom-left-radius: 40px;
        color: var(--primaryColor);
        width: 100%;
        height: 90px;
        line-height: 2rem;
        .boldText {
          font-size: 3rem;
          font-family: "Montserrat ExtraBold";
        }
        .lightText {
          font-size: 1rem;
          font-family: "Montserrat SemiBold";
        }
      }
      .rose2 {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: safe center;
        background-color: #e03278;
        border-top-right-radius: 40px;
        border-bottom-left-radius: 40px;
        color: var(--primaryColor);
        width: 100%;
        height: 90px;
        line-height: 2rem;
        .boldText {
          font-size: 3rem;
          font-family: "Montserrat ExtraBold";
        }
        .lightText {
          font-size: 1rem;
          font-family: "Montserrat SemiBold";
        }
      }
    }
  }
  .roseContainer {
    display: flex;
    justify-content: space-around;
    width: 100%;
    .rose3 {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      background-color: #e03278;
      border-top-right-radius: 40px;
      border-bottom-left-radius: 40px;
      color: var(--primaryColor);
      width: 50%;
      // max-width: 260px;
      height: 150px;
      .boldText {
        font-size: 1.4rem;
        font-family: "Montserrat SemiBold";
      }
      .bolderText {
        font-size: 5rem;
        font-family: "Montserrat ExtraBold";
        line-height: 3.5rem;
      }
      .lightText {
        font-size: 1rem;
        font-family: "Montserrat SemiBold";
      }
    }
  }
}
