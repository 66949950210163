@media screen and (min-width: 1024px) {
  .box-f {
    display: flex;
    flex-direction: column;
    align-items: center;
    .box-footer {
      margin-top: 100px;
      display: flex;
      flex-direction: row;
      justify-content: safe center;
      width: 100%;
      font-size: 0.7rem;
      .links {
        width: 50%;
        display: flex;
        justify-content: safe center;
        align-items: center;
        a {
          margin-left: 40px;
        }
        a:link {
          color: white;
          background-color: transparent;
          text-decoration: none;
        }

        a:visited {
          color: white;
          background-color: transparent;
          text-decoration: none;
        }

        a:hover {
          color: white;
          background-color: rgba($color: #fff, $alpha: 0.2);
          border-radius: 20px;
          text-decoration: none;
        }

        a:active {
          color: white;
          background-color: transparent;
          text-decoration: none;
        }
        span {
          margin-left: 15px;
        }
        .icon {
          width: 18px;
        }
      }
      margin-bottom: 100px;
    }
    .dots {
      width: 5%;
      margin-bottom: 2%;
    }
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 1023px) {
  .box-f {
    display: flex;
    flex-direction: column;
    align-items: center;
    .box-footer {
      margin-top: 100px;
      display: flex;
      flex-direction: row;
      justify-content: safe center;
      width: 100%;
      font-size: 1rem;
      .links {

        width: 80%;
        display: flex;
        justify-content: safe center;
        align-items: center;
        a {
          margin-left: 20px;
        }
        a:link {
          color: white;
          background-color: transparent;
          text-decoration: none;
        }

        a:visited {
          color: white;
          background-color: transparent;
          text-decoration: none;
        }

        a:hover {
          color: white;
          background-color: rgba($color: #fff, $alpha: 0.2);
          border-radius: 20px;
          text-decoration: none;
        }

        a:active {
          color: white;
          background-color: transparent;
          text-decoration: none;
        }
        span {
          margin-left: 5px;
        }
        .icon {
          width: 12px;
        }
      }
      margin-bottom: 50px;
    }
    .dots {
      width: 10%;
      margin-bottom: 5%;
    }
    margin-bottom: 50px;
  }
}
