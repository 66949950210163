@use "uniform" as *;

html,body{
  font-size: 16px;
  color: white;
}

.App {
  text-align: center;
}

:root {
  --primaryColor: white;
  --secondaryColor: rgb(14, 46, 102);
  scroll-padding-top: 150px;
  scroll-behavior: smooth;
}
.container {
  width: 100%;
max-width: 800px;
margin-right: auto;
margin-left: auto;
padding-right: 15px;
padding-left: 15px;
}


@media screen and (max-width: 767px) {
	html,body{
    font-size: 10px;
  }
}