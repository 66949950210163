
.textWeDo {
        margin-top: 10%;
        color: var(--primaryColor) !important;
        text-align: center;
        font-size: 1.5rem;
        line-height: 1.7rem;
        .boldText {
          font-family: "Montserrat Bold";
        }
      }
    .container-mapa {
      padding-top: 10%;
      display: flex;
      width: 100%;
      justify-content: center;
      .mapa {
        display: flex;
        width: 100%;
        max-width: 600px;
        height: auto;
        padding-left: 80px;
      }
      .mapa-mobile {
        display: flex;
        width: 100%;
        max-width: 300px;
        height: auto;
      }
      margin-bottom: 10%;
    }    
