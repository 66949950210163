.box {
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    max-width: 15%;
  }
  .texto {
    font-size: 1.5rem;
    line-height: 1.7rem;
    text-align: center;
    margin-top: 8%;
    color: var(--primaryColor) !important;
    .boldText {
        color: #c4f659;
      font-family: "Montserrat Bold";
    }
  }
}
